.contain-layer {position:fixed;left:0;top:0;right:0;bottom:0;display:flex;align-items:center;justify-content:center;background:rgba(0,0,0,0.8);z-index: 999;}
.contain-layer .dim {position: absolute; top: 0; left: 0;background:rgba(0,0,0, .4);width: 100vw;height: 100vh;}
.contain-layer .contain-inner {position:relative;z-index: 1000;padding:24px;width:756px;background:#fff;box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.16);border-radius: 12px;box-sizing:border-box;}

.contain-layer .box-scroll {width:auto;height:550px;margin:0 -16px -24px 0;padding:0 10px 0 0;overflow:auto;box-sizing:border-box;}
.contain-layer .box-scroll::-webkit-scrollbar {width:5px;}
.contain-layer .box-scroll::-webkit-scrollbar-thumb {background-color:#bcbcbc;}
.contain-layer .box-scroll::-webkit-scrollbar-track {background-color:#fff;}

.contain-layer h1.title {margin:0 0 28px 0;text-align:left;color:#3f3f3f;font-size:24px;font-weight:600;line-height:145%;letter-spacing:-0.02em;}
.contain-layer .table-type1 {margin:0 0 12px 0;border-spacing:2px;width:100%;border-collapse: separate;}
.contain-layer .table-type1 th {padding:12px 16px;text-align:center;color:#3f3f3f;font-size:16px;font-weight:700;line-height:135%;letter-spacing:-0.02em;background:#efefef;}
.contain-layer .table-type1 td {padding:12px 16px;text-align:left;color:#8a8a8a;font-size:16px;font-weight:400;line-height:135%;letter-spacing:-0.02em;background:#f5f5f5;}
.contain-layer .table-type1 td strong {color:#3f3f3f;}
.contain-layer .list-type1 li {padding:0 0 0 16px;text-indent:-6px;}
.contain-layer .list-type1 li::before {content:'•';display:inline-block;margin:0 7px 0 0;}
.contain-layer .box-privite >h1 {margin:0 0 20px 0;color:#3f3f3f;font-size:16px;font-weight:900;line-height:135%;letter-spacing:0.02em;}
.contain-layer .box-privite >p {margin:0 0 20px 0;color:#3f3f3f;font-size:16px;font-weight:400;line-height:135%;letter-spacing:0.02em;}

.contain-layer .btn-closelayer {display:block;position:absolute;right:24px;top:28px;width:24px;height:24px;text-indent:-1000px;overflow:hidden;background:url('../images/btn_close_popup.svg') no-repeat 50%;border:none;cursor:pointer;}
@media screen and (max-width: 788px) {
    .contain-layer .contain-inner {width:100%;margin:0 16px;}
    .contain-layer h1.title {margin:0 0 17px 0;color:#1f1f1f;font-size:20px;line-height:150%;}
    .contain-layer .box-privite >h1 {margin:0 0 26px 0;font-size:18px;}
    .contain-layer .box-privite >p {margin:0 0 26px 0;}
    .contain-layer .table-type1 {margin:0 0 16px 0;}
    .contain-layer .table-type1 th {font-size:14px;}
    .contain-layer .table-type1 td {font-size:14px;}
    .contain-layer .box-buttons {margin:16px 0 0 0;}
}