.contain-layer {position:fixed;left:0;top:0;right:0;bottom:0;display:flex;align-items:center;justify-content:center;z-index: 999;}
.contain-layer .dim {position: absolute; top: 0; left: 0;background:rgba(0,0,0, .4);width: 100vw;height: 100vh;}
.contain-layer .contain-inner {position:relative;z-index: 1000;padding:24px;width:756px;background:#fff;box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.16);border-radius: 12px;box-sizing:border-box;}

.contain-layer h1.title {margin:0 0 28px 0;text-align:center;color:#3f3f3f;font-size:24px;font-weight:600;line-height:145%;letter-spacing:-0.02em;}
.contain-layer .text-information1 {margin:0 0 20px 0;color:#3f3f3f;font-size:16px;font-weight:400;line-height:135%;letter-spacing:0.02em;}
.contain-layer .text-information2 {margin:0 0 20px 0;color:#8a8a8a;font-size:14px;font-weight:400;line-height:150%;letter-spacing:0.01em;}
.contain-layer .table-type1 {margin:0 0 12px 0;border-spacing:2px;width:100%;border-collapse: separate;}
.contain-layer .table-type1 th {padding:12px 16px;text-align:center;color:#3f3f3f;font-size:16px;font-weight:700;line-height:135%;letter-spacing:-0.02em;background:#efefef;}
.contain-layer .table-type1 td {padding:12px 16px;text-align:left;color:#8a8a8a;font-size:16px;font-weight:400;line-height:135%;letter-spacing:-0.02em;background:#f5f5f5;}
.contain-layer .table-type1 td strong {color:#3f3f3f;}
.contain-layer .list-type1 li {padding:0 0 0 16px;text-indent:-6px;}
.contain-layer .list-type1 li::before {content:'•';display:inline-block;margin:0 7px 0 0;}
.contain-layer .box-buttons {margin:44px 0 0 0;text-align:center;}
.contain-layer .btn-type1 {display:inline-block;margin:0 4px;padding:0 30px;height:44px;color:#fff;font-size:16px;font-weight:700;letter-spacing:-0.01em;background:#1f1f1f;border:none;border-radius:8px;}
.contain-layer .btn-type2 {display:inline-block;margin:0 4px;padding:0 30px;height:44px;color:#fff;font-size:16px;font-weight:700;letter-spacing:-0.01em;background:#db1e26;border:none;border-radius:8px;}
@media screen and (max-width: 788px) {
    .contain-layer .contain-inner {width:100%;margin:0 16px;}
    .contain-layer h1.title {margin:0 0 17px 0;font-size:18px;line-height:150%;}
    .contain-layer .text-information1 {margin:0 0 16px 0;font-size:14px;}
    .contain-layer .text-information2 {margin:0 0 16px 0;line-height:135%;}
    .contain-layer .table-type1 {margin:0 0 16px 0;}
    .contain-layer .table-type1 th {font-size:14px;}
    .contain-layer .table-type1 td {font-size:14px;}
    .contain-layer .box-buttons {margin:16px 0 0 0;}
}
