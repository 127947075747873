/* Fonts */
@font-face {
    font-family: "Pretendard Variable";
    font-weight: 45 920;
    font-style: normal;
    font-display: swap;
    src: local("Pretendard Variable"),
    url('../fonts/PretendardVariable.woff2') format("woff2-variations");
}

/* Common */
html, body, div, span, applet, object, 
iframe, h1, h2, h3, h4, h5, h6, p, blockquote, 
pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, 
strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, 
ol, ul, li, fieldset, form, label, legend, table, caption, 
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, 
embed, figure, figcaption, footer, header, hgroup, menu, nav, output, 
ruby, section, summary, time, mark, audio, video, option {
    font-family: "Pretendard Variable";
}

p {padding:0; margin:0;}

/* Alert */
.contain-alert {z-index: 999; position:fixed; left:0; top:0; right:0; bottom:0; display:none; align-items:center; justify-content:center; background:rgba(0,0,0,0.8);}
.contain-alert.active {display: flex;}
.contain-alert .contain-inner {padding:24px 20px 16px; width:320px; background:#fff; box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.16); border-radius:8px; box-sizing:border-box;}

.contain-alert .box-alert-body {}
.contain-alert .icon-info {margin:0 auto; display:block; width:28px; height:28px; background:#f04846 url('../images/ico_info_alert.svg') no-repeat center; background-size:2px 16px; border-radius:100%;}
.contain-alert .text-msg {margin-top:14px; font-size: 16px; font-weight:700; line-height:23px; letter-spacing:0em; text-align:center;}

.contain-alert .box-alert-btn {margin-top:24px;}
.contain-alert .btn-closelayer {margin: 0 auto; display:block; width:84px; height:44px; border-radius:8px; cursor:pointer; background:#1f1f1f; color:#fff; font-family:Pretendard Variable; font-size:16px; font-weight:500; line-height:23px; text-align:center;}

