/* Fonts */
@font-face {
    font-family: "Pretendard Variable";
    font-weight: 45 920;
    font-style: normal;
    font-display: swap;
    src: local("Pretendard Variable"),
    url('../fonts/PretendardVariable.woff2') format("woff2-variations");
}

/* Common */
html, body, div, span, applet, object, 
iframe, h1, h2, h3, h4, h5, h6, p, blockquote, 
pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, 
strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, 
ol, ul, li, fieldset, form, label, legend, table, caption, 
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, 
embed, figure, figcaption, footer, header, hgroup, menu, nav, output, 
ruby, section, summary, time, mark, audio, video, option {
    font-family: "Pretendard Variable";
}

h1 {margin-block-start:0; margin-block-end:0;}


/* scr-sup-1021 */
.contain-layer-news { z-index: 999;position:fixed; left:0; top:0; right:0; bottom:0; display:none; align-items:center; justify-content:center;}
.contain-layer-news.active {display: flex;}

.contain-layer-news .dim {width: 100%; height: 100vh; background-color: rgba(0,0,0, .8); position: absolute; top: 0; left: 0;}

.contain-layer-news .contain-inner {position:relative; padding:40px; width:960px; background:#fff; box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.16); border-radius:12px; box-sizing:border-box;}

.contain-layer-news .box-scroll {height:541px; margin: 0 -32px -40px 0; overflow:auto; box-sizing:border-box; padding-right:32px;} 
.contain-layer-news .box-scroll::-webkit-scrollbar {width:5px; height: 5%;}
.contain-layer-news .box-scroll::-webkit-scrollbar-thumb {background-color:#bcbcbc;}

.contain-layer-news .box_layer_header {display:flex; align-items:center; margin-bottom:24px; gap:71px; width: calc(100% - 30px);}
.contain-layer-news .text_item_category {font-size:20px; font-weight:900; line-height:29px; letter-spacing:-0.02em; color:#db1e26; max-width: 62px;}
.contain-layer-news h1.title {text-align:left; color:#3f3f3f; font-size:24px; font-weight:600; line-height:145%; letter-spacing:-0.02em; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}

.contain-layer-news .box-privite img + p {margin-top: 22px;}
.contain-layer-news .box-privite >img {display: block; width:300px; height:300px;}
.contain-layer-news .box-privite >p {margin:0 0 20px 0; color:#3f3f3f; font-size:16px; font-weight:400; line-height:135%; letter-spacing:0.02em;}
.contain-layer-news .btn-closelayer {display:block; position:absolute; right:24px; top:24px; width:24px; height:24px; text-indent:-1000px; overflow:hidden; background:url('../images/btn_close_popup.svg') no-repeat 50%; border:none; cursor:pointer;}


/* midia */
@media screen and (max-width: 788px) {
    .contain-layer-news .box_layer_header {gap:20px; width: calc(100% - 20px);}
    .contain-layer-news .contain-inner {width:100%; margin:0 16px;}
    .contain-layer-news h1.title {color:#1f1f1f; font-size:20px; line-height:150%;}
    .contain-layer-news .box-privite >h1 {margin:0 0 26px 0; font-size:18px;}
    .contain-layer-news .box-privite >p {margin:0 0 26px 0;}
    .contain-layer-news .table-type1 {margin:0 0 16px 0;}
    .contain-layer-news .table-type1 th {font-size:14px;}
    .contain-layer-news .table-type1 td {font-size:14px;}
    .contain-layer-news .box-buttons {margin:16px 0 0 0;}
}